import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type SessionService from 'ember-simple-auth/services/session';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type { AlertMessage } from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type { UntypedService } from 'shared/types';

export default class ApplicationController extends Controller {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare browserSupport: UntypedService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare userAgent: UserAgentService;

  @tracked newSocialProfile: SocialProfileModel | null = null;
  isUatTest = false;

  get uaClass(): string {
    return this.browserSupport.userAgentCssClass;
  }

  get isDev(): boolean {
    return this.auth.currentUserModel?.dev;
  }

  get isAuthenticated(): boolean {
    return this.session.isAuthenticated;
  }

  get isMobile(): boolean {
    return this.userAgent.device.isMobile;
  }

  get canUseCommandPalette(): boolean {
    return this.isAuthenticated && this.isDev && !this.isMobile;
  }

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    if (this.isMobile) {
      this.#handleDeviceOrientationWarning();
    }
  }

  get alertsQueue(): AlertMessage[] {
    return this.alerts.queue;
  }

  get isLinkinbioRoute(): boolean {
    return this.router.currentURL.includes('linkinbio');
  }

  get isInfluencersRoute(): boolean {
    return this.router.currentURL.includes('partnerships');
  }

  get isProfileCategorized(): boolean {
    return Boolean(this.newSocialProfile?.industry) && Boolean(this.newSocialProfile?.businessModel) && !this.isUatTest;
  }

  get hideProfileCategorization(): boolean | undefined {
    return this.isLinkinbioRoute || this.isInfluencersRoute;
  }

  get shouldCategorizeProfile(): boolean | undefined {
    return this.hideProfileCategorization
      ? false
      : this.newSocialProfile?.isInstagram && !this.newSocialProfile?.isInstagramLogin;
  }

  @action
  closeProfileCategorizationModal(): void {
    this.newSocialProfile = null;
    this.router.transitionTo(this.router.currentRouteName, { queryParams: { new_social_profile_id: null } });
  }

  #handleDeviceOrientationWarning(): void {
    const initialWindowWidth = window.innerWidth;
    const mediaQueryListObject = window.matchMedia('(orientation: landscape)');

    if (mediaQueryListObject.matches) {
      this.#displayLandscapeWarning();
    }

    mediaQueryListObject.onchange = (e) => {
      if (e.matches && window.innerWidth !== initialWindowWidth) {
        this.#displayLandscapeWarning();
      }
    };
  }

  #displayLandscapeWarning(): void {
    this.alerts.warning(this.intl.t('errors.landscapeWarning.message'), {
      title: this.intl.t('errors.landscapeWarning.title'),
      preventDuplicates: true
    });
  }
}
